import axios from 'axios';
import AppConfig from '@/config';
import { getCurrentGeoPosition } from '@/helpers/GeolocationHelper';

export const MAP_ACCESS_TOKEN = AppConfig.getAsString('mapboxToken');
export const MAP_STYLE_URL = 'mapbox://styles/mapbox/light-v9';
export const MAP_LAYER_CURRENT_POSITION = {
  id: 'map-layer-current-position',
  type: 'circle',
  paint: {
    'circle-radius': 10,
    'circle-color': '#3887be',
  },
};
export const MAP_LAYER_ROUTE_DESTINATION = {
  id: 'map-layer-route-destination',
  type: 'line',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#3887be',
    'line-width': 5,
  },
};

export const getMapCurrentPositionSource = (coordinates: number[]) => {
  return {
    id: 'map-source-current-position',
    source: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates,
            },
          },
        ],
      },
    },
  };
};

export const getMapRouteDestinationSource = (coordinates: number[][] | number[]) => {
  return {
    id: 'map-source-route-destination',
    source: {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates,
        },
      },
    },
  };
};

export const getMapBounds = (coordinates: { lat: number; lng: number }[]) => {
  let minLat = 0;
  let maxLat = 0;
  let minLng = 0;
  let maxLng = 0;

  if (coordinates.length) {
    maxLat = minLat = coordinates[0].lat;
    maxLng = minLng = coordinates[0].lng;

    coordinates.forEach((c) => {
      if (c.lat !== null && c.lng !== null) {
        minLat = minLat < c.lat ? minLat : c.lat;
        maxLat = maxLat > c.lat ? maxLat : c.lat;
        minLng = minLng < c.lng ? minLng : c.lng;
        maxLng = maxLng > c.lng ? maxLng : c.lng;
      }
    });

    if (coordinates.length === 1) {
      minLat -= 0.0005;
      maxLat += 0.0005;
      minLng -= 0.0005;
      maxLng += 0.0005;
    }
  }
  return [
    [minLng, minLat],
    [maxLng, maxLat],
  ];
};

export const getMapDirectionFromCurrentPosition = (destinationCoords: { lat: number; lng: number }) => {
  return new Promise((resolve, reject) => {
    const { latitude, longitude } = getCurrentGeoPosition();
    if (latitude === null || longitude === null) {
      reject({ message: "Can't detect the current user position." });
      return;
    }

    const currentPosition = [longitude, latitude];
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${currentPosition.join(',')};${
      destinationCoords.lng
    },${destinationCoords.lat}?steps=true&geometries=geojson&access_token=${MAP_ACCESS_TOKEN}`;

    axios
      .get(url)
      .then(({ data }) => {
        if (!data.routes.length) {
          reject({ message: "Can't build route to chosen location." });
          return;
        }
        const routeCoordinates: number[][] = data.routes[0].geometry.coordinates;
        resolve({ currentPosition, routeCoordinates });
      })
      .catch((err) => {
        reject({ requestError: true, data: err, message: "Can't build route to chosen location." });
      });
  });
};
